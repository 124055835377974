import child from "../assets/child.jpg";
import radiology from "../assets/radiology.jpg";
import aesthetic from "../assets/aesthetic.jpg";
import therapy from "../assets/therapy.jpg";
import orthopedic from "../assets/orthopedic.jpg";
import microscope from "../assets/microscope.jpg";
import paradontologia from "../assets/paradontologia.png";
import implanti from "../assets/implanti.jpg";
import cleaning from "../assets/cleaning.jpg";
import surgery from "../assets/surgery.jpg";

import eteri from "../assets/dentistImages/eteri.jpg";
import aleqsi from "../assets/dentistImages/aleqsi.jpeg";
import marine from "../assets/dentistImages/marine.jpeg";
import np from "../assets/dentistImages/nophoto.png";
import daria from "../assets/dentistImages/daria.jpg";
import gulnara from "../assets/dentistImages/gulnara.jpg";
import qetevan from "../assets/dentistImages/qetevan.jpeg";
import nodari from "../assets/dentistImages/nodari.jpeg";
import maia from "../assets/dentistImages/maia.jpeg";
import megimamuladze from "../assets/dentistImages/maiadiasamidze.jpg";
import nino from "../assets/dentistImages/nino.jpeg";
import lia from "../assets/dentistImages/lia.jpeg";
import maiadiasamidze from "../assets/dentistImages/maiadiasamidze.jpeg";
import orthodontics from "../assets/orthodontics.webp"

import work3 from '../assets/works/work-3.jpeg'

import work7 from '../assets/works/work7.jpg'
import work8 from '../assets/works/work8.jpg'
import cvin1 from '../assets/works/cvin1.jpeg'
import cvin2 from '../assets/works/cvin2.jpeg'
import cvin3 from '../assets/works/cvin3.jpeg'
import cvin4 from '../assets/works/cvin4.jpeg'
import cirk1 from '../assets/works/cirk1.jpeg'
import cirk2 from '../assets/works/cirk2.jpeg'
import cirk3 from '../assets/works/cirk3.jpeg'
import cirk4 from '../assets/works/cirk4.jpeg'
import br1 from '../assets/works/breketi1.jpeg'
import br2 from '../assets/works/breketi2.jpeg'
import br3 from '../assets/works/breketi3.jpeg'
import br4 from '../assets/works/breketi4.jpeg'
import br5 from '../assets/works/breketi5.jpeg'
import br6 from '../assets/works/breketi6.jpeg'

export const services = [
  {
    name: "ბავშვთა სტომატოლოგია",
    img: child,
    desc:"ფოკუსირებულია  ბავშვებისა და მოზარდების პირის ღრუს ჯანმრთელობაზე. პედიატრი სტომატოლოგები სპეციალიზირებულნი არიან ასაკის შესაბამისი სტომატოლოგიური მოვლის, პრევენციული ღონისძიებებისა და განათლების უზრუნველყოფაში ჯანსაღი ჩვევების პოპულარიზაციისთვის.",
    id:1
  },
  {  
    id:2,
    name: "ორთოდონტია",
    img: orthodontics,
    desc:"ორთოდონტია სტომატოლოგიის ნაწილია,რომელიც შეისჭავლის კბილისქსოვილების შენებასა და ფუნციებს,მისი მკურნალობის მეთოდებსა და ტექნიკას",
    // info:`რა არის ორთოდონტია?
    // ორთოდონტია (ბერძნული სიტყვაა,orthos-პირდაპირი, ლათ, dens-
    // კბილი)წარმოადგენს კბილების არასწორი დგომისა და თანკბილვის
    // გასწორების ყველაზე ეფექტურ და რეკომენდებულ გზას.რომელიც
    // სწავლობს ყბა-სახის სისტემის განვითარებას, ანომალიებს,
    // დეფორმაციების ეტიმოლოგიასა და პათოგენეზს. ორთოდონტიის
    // ფარგლებში ხდება დარღვევების პროფილაქტიკა, დიაგნოსტიკა და
    // მკურნალობა.საუკეთესო ესთეტიკური ეფექტის მიღება მხოლოდ
    // ორთოდონტიული მკურნალობის დახმარებითაა შესაძლებელი.
    // სხვადასხვა სახის ბრეკეტ-სისტემების საშუალებით კი შედეგის
    // მიღწევა ოპტიმალურ დროშია შესაძლებელი.
    // ბრეკეტ-სისტემის სახეობები
    // არსებობს მოუხსნელი და მოსახსნელი აპარატები
    // მოუხსნელ აპარატებს მიეკუთვნება ბრეკეტ სისტემა,თვითონ
    // ბრეკეტებშიც გვხვდება ნაირსახეობები
    // მოუხსნელ აპარატებს ასევე მიეკუთვნება სასის რკალი და სასის
    // ექსპანდერი.
    // მზადდება სატექნიკო ლაბორატორიაში თითოეული პაციენტისთვის
    // ინდივიდუალურად, ვიზუალურად ღიმილის დროს შეუმჩნეველია,
    // ფიქსირდება პირის ღრუში სასის მხრიდან. გამოიყენება ყბის
    // გასაფართოვებლად
    // ბრეკეტ-სისტემები ერთმანეთისაგან განსხვავდება:
    // კონსტრუქციით - ლიგატურული და თვითლიგირებადი
    // ფიქსაციის მიხედვით - ვესტიბულური და ლინგვალური
    // მასალის მიხედვით - მეტალის, კერამიკის და საფირის(ესთეტიკური)
    // მოსახსნელ აპარატებს მიეკუთვნება ფირფიტა,ტრეინერი,,ორთ.
    // ქუდი, კაპები-(ელაინერები)კბილთა დგომის გამოსასწორებლად,
    // ცვეთის საწინააღმდეგო კაპა,შედეგის შემანარჩუნებელი კაპა-
    // რეტეინერი
    // `
  },
  {
    id:3,
    name: "დენტალური იმპლანტაცია",
    img: implanti,
    desc:"კბილების აღდგენის თანამედროვე მეთოდი, როდესაც ხდება ყბის ძვალში ტიტანისაგან და მისი შენადნობებისგან დამზადებული იმპლანტის ჩამაგრება",
    
  },
  {
    id:4,
    name: "ციფრული რადიოლოგია",
    img: radiology,
    desc:"დენტალური რენტგენი თქვენს სტომატოლოგს საშუალებას აძლევს, მიიღოს ამომწურავი ინფორმაცია პირის ღრუს მაგარ (კბილებსა და ძვლებზე) და რბილ ქსოვილებზე, რომლებიც თქვენს კბილებს ყბის ძვალში აფიქსირებს",
    info:`რა უპირატესობები გააჩნია დენტალურ რადიოგრაფს?
    დენტალური რენტგენი თქვენს სტომატოლოგს საშუალებას აძლევს, მიიღოს ამომწურავი ინფორმაცია პირის ღრუს მაგარ (კბილებსა და ძვლებზე) და რბილ ქსოვილებზე, რომლებიც თქვენს კბილებს ყბის ძვალში აფიქსირებს. მაგალითად ექი­მებს შეუძლიათ დაინახონ:
    
     
    
    კარიესული დაზიანებები, რომლებიც ლოკალიზებულია კბილთაშორის სივრცეებსა და ბჟენების ქვეშ
    ანთებითი ცვლილებები ძვლებში
    ცვლილებები პაროდონტის ქსოვილებში
    ინფექციები, რომლებიც თქვენი ღრძილების ქვეშ ვითარ­დება
    სიმსივნური წარმონაქმნები
    ბავშვთა ასაკში ასეთი რენტგენოგრამები განსაკუთრებით ინფორმატიულია, რადგან მათი საშუალებით ექიმი აფასებს პატარას ყბების, კბილებისა და კბილთა ჩანასახების განვითა­რებას.
     
    
    რენტგენი გვეხმარება დაავადების იდენტიფიცირებაში მანამ, სანამ ამ დაავადების სიმპტომები თავს იჩენს და სერიოზულ პრობლემად ექცევა ჩვენს პაციენტებს.
    
     
    
    რამდენი სურათი შეიძლება გადავიღოთ?
    ეს დამოკიდებულია პაციენტის პირის ღრუს მდგომარეობაზე, სიმპტომებზე რომელსაც თქვენ აღწერთ, თქვენი ავადობის ისტორიაზე, სხვადასხვა რისკ ფაქტორებზე, თქვენს ასაკზე, და ა.შ. რაოდენობას განსაზღვრავს ექიმი როგორც დიაგნოსტი­კის, ისე მკურნალობის ეტაპზე. სხვადასხვა მანიპულაციის ჩა­სატარებლად რენტგენის სურათების განსხვავებული რაოდე­ნობაა საჭირო (1-დან 3-მდე).
    
     
    
    ინდივიდუალური დაცვის საშუალებები
    უნდა გაითვალისწინოთ, რომ რენტგენის გადაღების დროს სტომატოლოგიური კლინიკის მომსახურე პერსონალმა უნდა მოგახუროთ (აგაფაროთ) დამცავი წინსაფარი. ასევე შესაძ­ლებელია ყელის ფიქსატორის გაკეთება, თუ მისი მოთავსება ხელს არ შეუშლის ჩასატარებელ რენტგენოლოგიურ პროცე­დურას.
    
     
    
    ორსული პაციენტები
    ორსულმა პაციენტებმა თქვენი მდგომარეობის შესახებ სტო­მატოლოგს აუცილებლად აცნობეთ, რადგან პირის ღრუს მოსაწესრიგებლად რენტგენის გადაღება შეიძლება გახდეს საჭირო, თქვენი მდგომარეობა კი მკურნალობის გეგმაში უნდა იყოს გათვალისწინეული. რენტგენის გადაღება ხდე­ბა იმ შემთხვევაში, თუ რენტგენოლოგიური ვიზუალიზაციის გარეშე სტომატოლოგიური დახმარების აღმოჩენა შეუძლე­ბელია ან არსებობს გართულების რისკი, რა შემთხვევაშიც „გონივრული დასაშვებობის“ პრინციპით უნდა ვიმოქმე­დოთ.
    
     
    
    დენტალუქსში შესაძლებელია ყველა ტიპის რენტგენოლოგიური გამოკვლევა.კლინიკა აღჭურვილია ACTEON ის ულტრათანამედროვე აპარატებით:`
  },
  {
    id:5,
    name: "პაროდონტოლოგია",
    img: paradontologia,
    desc:`პაროდონტოლოგია სტომატოლოგიის ერთ-ერთი
    მნიშვნელოვანი დარგია, რომელიც შეისწავლის ღრძილების
    დაავადებებს და პასუხს აგებს პირის ღრუს ჯანმრთელობაზე.`,
    info:`პაროდონტოლოგია სტომატოლოგიის ერთ-ერთი
    მნიშვნელოვანი დარგია, რომელიც შეისწავლის ღრძილების
    დაავადებებს და პასუხს აგებს პირის ღრუს ჯანმრთელობაზე.
    პარადონტი არის ქსოვილთა კომპლექსი , რომელიც აერთიანებს
    კბილის ირგვლივ არსებულ რბილ და მაგარ ქსოვილებს. მათი
    ჯანმრთელობა განაპირობებს კბილის სიმყარეს ყბის ძვალში.
    პაროდონტოლოგია ითვალისწინებს ღრძილების დაავადების
    თერაპიულ და ქირურგიულ მკურნალობას.
    ზოგჯერ პაროდონტოლოგიულ სტატუსს უფრო მეტის თქმა
    შეუძლია ჩვენს ზოგად ჯანმრთელობაზე. კბილებზე დაგროვილმა
    ნადებმა შეიძლება გამოიწვიოს ბევრი სისტემური დაავადების
    პროვოცირება. მაგ. დიაბეტი, გულ-სისხლძარღვთა დაავადებები და
    სხვა.
    პაროდონტის დაავადებებია:
    გინგივიტი (ღრძილების ანთება)
    პაროდონტიტი (ანთებითი დაავადება, რომელსაც ახასიათებს
    კბილის ირგვლივ მდებარე ძვლის დაშლა)
    ღრძილების რეცესია (ღრძილების დაწევა და ფესვების
    გაშიშვლება)
    პერიიმპლანტიტები (იმპლანტის გარშემო ქსოვილების ანთება)
    კლინიკა „დენტა-ლუქსში“ პაროდონტის ანთებითი
    დაავადებების მკურნალობა მიმდინარეობს უახლესი მეთოდებით და
    აპარატურით.
    პაროდონტიული მკურნალობა მოიცავს:
    1. დიაგნოსტიკა;
    2. კბილების პროფესიული წმენდა (ორი აპარატის Piezon Masteri-
    ის და Air Flow-ს გამოყენებით) ;
    3. გინგივიტის მკურნალობა;
    4. პარადონტიტის მკურნალობა “Vector Durr” აპარატით;
    5. პათოლოგიური ჩიბეების აღდგენა რეგენერაციის გზით;
    6. გინგივოტომია-ღრძილის კორექცია;
    7. სქეილინგი ფესვის ინსტრუმენტირებით;
    კლინიკა „დენტა-ლუქსში“ ასევე შესაძლებელია ესთეთიკური
    სტომატოლოგიური მომსახურების ჩატერება (კბილების
    ქიმიური გათეთრება სხვადასხვა მეთოდით). სასურველ
    პროცედურას პაციენტი თავად ირჩევს.`
  },
  {
    id:6,
    name: "ორთოპედია",
    img: orthopedic,
    desc:"სტომატოლოგიის სპეციალიზებული სფერო, რომელიც ეხება ყბის და სახის სტრუქტურების გასწორებასა და პოზიციონირებას. ეს შეიძლება მოიცავდეს ორთოდონტიული ტექნიკის გამოყენებას ისეთი საკითხების გამოსასწორებლად, როგორიცაა მალოკლუზია (არასწორი ნაკბენი), ყბის არასწორი განლაგება ან სახის ასიმეტრია. ",
   
  },
  // {
  //   id:7,
  //   name: "თერაპიული მკურნალობა",
  //   img: therapy,
  //   desc:"მოიცავს ინტერვენციებს, რომლებიც მიმართულია პირის ღრუს ჯანმრთელობის აღდგენასა და შენარჩუნებაზე მაგალითად სხვადასხვა პროცედურებს სტომატოლოგიური პრობლემების სამკურნალოდ, როგორიცაა კარიესი, ღრძილების დაავადება, ინფექციები და დაზიანებული კბილები. ",
    
  // },
  {
    id:8,
    name: "ქირურგიული სტომატოლოგია",
    img: surgery,
    desc:"ეს არის სტომატოლოგიის უმნიშვნელოვანესი ნაწილი, რომელიც მოიცავს მკურნალობის ოპერაციულ მეთოდებს, როგორიცაა: კბილის ამოღება,ბის ძვლოვანი ქსოვილების გადანერგვა, იმპლანტაცია, ასევე სხვადასხვა ესთეტიკური ოპერაციები პარადონტოლოგისა და ორთოდონტიაში.",
    
  },
  {
    id:9,
    name: "ჰიგიენური წმენდა",
    img: cleaning,
    desc:"სტომატოლოგიური წმენდა გულისხმობს კბილების ზედაპირიდან ნადების და კბილის საფუძვლიან მოცილებას. სტომატოლოგიური ჰიგიენისტი იყენებს სპეციალიზირებულ ინსტრუმენტებს კბილების გასაწმენდად და გასაპრიალებლად, პირის ღრუს ჯანმრთელობის გასაუმჯობესებლად, გაფუჭების თავიდან ასაცილებლად და სუფთა, ნათელი ღიმილის უზრუნველსაყოფად.",
    
  },
  {
    id:10,
    name: "მიკროსკოპული სტომატოლოგია",
    img: microscope,
    desc:"ზუსტი და მინიმალური ინვაზიური სტომატოლოგიური პროცედურების შესასრულებლად მიკროსკოპულ დონეზე. ეს მიდგომა აძლიერებს სიზუსტეს, რაც სტომატოლოგებს საშუალებას აძლევს დაადგინონ და განიხილონ პრობლემები მეტი სიზუსტით, რაც იწვევს გაუმჯობესებულ შედეგებს და პაციენტის კომფორტს.",
    
  },
  
];
export const workingHours = [
  {
    day: "ორშაბათი",
    hours: "10:00 - 19:00",
    break: "",
    working: true,
  },
  {
    day: "სამშაბათი",
    hours: "10:00 - 19:00",
    break: "",
    working: true,
  },
  {
    day: "ოთხშაბათი",
    hours: "10:00 - 19:00",
    break: "",
    working: true,
  },
  {
    day: "ხუთშაბათი",
    hours: "10:00 - 19:00",
    break: "",
    working: true,
  },
  {
    day: "პარასკევი",
    hours: "10:00 - 19:00",
    break: "",
    working: true,
  },
  {
    day: "შაბათი",
    hours: "10:00 - 16:00",
   
    working: true,
  },
  {
    day: "კვირა",
    hours: "",
    break: "",
    working: false,
  },
];

export const dentists = [
  {
    name: "გულნარა დიასამიძე",
    position: "დირექტორი",
    tel: "123",
    education:[
      "1975-1982 მოსკოვის ი. სემაშკოს სახელობის სახელმწიფო სტომატოლოგიური ინსტიტუტი",
      "1984-1987წ სტაჟირება მოსკოვის სახელმწიფო სტომატოლოგიური უნივერსიტეტის ორთოპედიული სტომატოლოგიის კათედრა.",
      `2001წ სახელმწიფო სერთიფიკატი ორთოპედიული სტომატოლოგია. ჯანდაცვის ორგანიზაცია. 
      სხვადასხვა შიდა და გარე საერთასორისო სტომატოლოგიურ კონფერენციებსა და კონგრესებში მონაწილეობა. 
      მასტერ კლასები მოსკოვისა და სტამბულის წამვან კლინიკებში`
      

    ],
    courses:[
      `2001წ სახელმწიფო სერთიფიკატი ორთოპედიული სტომატოლოგია. ჯანდაცვის ორგანიზაცია. 
      სხვადასხვა შიდა და გარე საერთასორისო სტომატოლოგიურ კონფერენციებსა და კონგრესებში მონაწილეობა. 
      მასტერ კლასები მოსკოვისა და სტამბულის წამვან კლინიკებში`
    ],
    img: gulnara,
  },
  {
    name: "ქეთევან ყუშიტაშვილი",
    position: "სტომატოლოგი",
    tel: "123",
    img: qetevan,
    education: [
      "2002-2007 წწ თბილისის ივ.ჯავახიშვილის სახელობის სახელმწიფო უნივერსიტეტი(ფაკულტეტი სტომატოლოგია)",
      "2007-2009 წწ მიენიჭა სახელმწიფო სერთიფიკატი, უმაღლესი სამედიცინო განათლების დიპლომი,ნებადართული  სპეციალობა-“ორთოპედიული სტომატოლოგია”",
      "2009-2010 წწ მიენიჭა სახელმწიფო სერთიფიკატი,უმაღლესი სამედიცინო განათლების დიპლომი, ნებადართული სპეციალობა-“თერაპიული სტიმატოლოგია”",
      "2015-2020 წწ “შპს” დენტალუქსის თერაპიული სტომატოლოგიის სარეზიდენტურო პროგრამის ხემძღვანელი",
      "2010წ მე-6 საერთაშორისო სტომატოლოგიური კონგრესი ბათუმი. სერთიფიკატი",
      "2012-2013 წწ საქართველოს შრომის, ჯანმრთელობისა და სოციალური დაცვის სამინისტრო “მადლობის სიგელი” რეიტინგულ სტუდენტთა სტაჟირების პროგრამაში შეტანილი წვლილისთვის",
      "2013 წ.პირველი სტომატოლოგიური ლაზერული მასტერკლასი, სემინარი პარადონტოლოგიასა და ქირურგიაში. “ლაზერი-ახალი სამყარო სტომატოლოგიაში” Proffessor IGOR SHUGAYIOV(president of Russian Lazer Dental Association) ",
      "2014 წ.მე-10 საერთაშორისო სტომატოლოგიური კონგრესი. ",
      "2014 წ.GSA კონფერენცია,ახალი ხედვა სტომატოლოგიაში ",
      "2015 წ.მე-11 საერთაშორისო სტომატოლოგიური კონგრესი",

      "2015 წ.”სტომატოლოგიურ მომსახურებასთან ასოცირებული ინფექციის კონტროლი”(სერთიფიკატი) მედიცინის მეცნიერებათა დოქტორი პაატა იმნაძე",
      "2016 წ.მე-12საერთაშორისო სტომატოლოგიური კონგრესი",
      "2018 წ. GDPA სემინარი”ტკივილის მენეჯმენტი სტომატოლოგიაში”(მარინა მამალაძე)",
       "2021 წ.ინტენსიური სასწავლო კურსი”პაციენტის კურაცია შემანარჩუნებელი თერაპია”(ელენა კლიმენკო)კიევი",


      "2021 წ.ვებინარი”საღეჭი კბილების პირდაპირი რესტავრაციები, ბონდინგი”DR.Maxim Belograd.(კიევი)",
      "2021წ.ვებინარი “ენდოდონტიური წვდომა”(ონლაინ მასტერკლასი)DR.Maxim Belograd(კიევი)",
      "2021წ.ვებინარი”დენტალური ფოტოგრაფია”(ონლაინ მასტერკლასი)DR.Bruno Pellissari(ბრაზილია)",
      " 2022წ.სემინარი”პერიოდონტიტი და პერიმპლანტი-ბიოლოგიურად ორიენტირებული,მინიმალურად ინვაზიური მკურნალობა”Ivan Cotovic Kor Durr Dental(Croatia)",
      "2022წ.სემინარი”ვერტიკალური თავისუფლების კონცეფცია.რბილი ქსოვილის ბიოდინამიკური ფორმა”  ლექტორი S.Conti(იტალია)",
      "2023წ.სემინარი,მასტერკლასი-“პროთეზი და ესთეტიკა სტომატოლოგიაში.სრული იმერსია”Dr.Massironi(იტალია)",
      "2023წ.სემინარი,მასტერკლასი”იმპლანტზე პროთეზირება:გრძელვადიანი წარმატების ძირითადი ფაქტორები DR.Vakhtang Pateishvili(საქართველო)",
      "2023წ.სემინარი,მასტერკლასი”დენტალური ფოტოგრაფია(საქართველო)2023წ.სემინარი მასტერკლასი”ესთეტიკური რეაბილიტაციის დაგეგმარება”",
      "2023წ.სემინარი, მასტერკლასი”პრეპარირების სრული კურსი:ვინირები,გვირგვინები, ჩანართები ",
      
    ],
   
  },
  {
    name: "ლია მაღლაკელიძე",
    position: "სტომატოლოგი",
    tel: "123",
    img: lia,
    education:[
      '1989–1992 წწ  ხარკოვის სახელმწიფო სამედიცინო უნივერსიტეტი( ფაკულტეტი სტომატოლოგია).',
      '1993–1994წწ –თბილისის სახელმწიფო სამედიცინო უნივერსიტეტი (ფაკულტეტი სტომატოლოგია).',
      '1994 წელს მიენიჭა სახელმწიფო სერტიფიკატი,უმაღლესი სამედიცინო განათლების დიპლომი,ნებადართული სპეციალობა–თერაპიული სტომატოლოგია.',
      '2001წ სახელმწიფო სერტიფიცირება –მიმართულება –თერაპიული სტომატოლოგია უმაღლესი ქულით.',
      '1998–2005წწ ბათუმის შოთა რუსთაველის სახელმწიფო უნივერსიტეტის,სამედიცინო ფაკულტეტის,სტომატოლოგიური თერაპიის პრაქტიკის და სალექციო კურსის ხელმძღვანელი.',

      ' 2004წ მასტერ კლასი,სემინარი "პრობლემატური არხების მკურნალობა" "უახლესი ტექნოლოგიები რესტავრაციულ სტომატოლოგიაში”',
      ' ვ.ჩელიკინი,ვ.სადოვსკი (რუსეთი) 2005 წ –GSA | საერთაშორისო სტომატოლოგიური კონგრესი (ბათუმი)  სერტიფიკატი ',
      '2006წ –|| საერტაშორისო სტომატოლოგიური კონგრესი (ბათუმი) სერტიფიკატი. ',
      '2007 წ – "თანამედროვე ხედვა კბილების რესტავრაციაში,კბილების ფუნქციური და ესთეტიკური აღდგენა,მასტერკლასი,სემინარი ს.რადლინსკი .,კლინიკა–სტუდია  აპოლონია პოლტავა(უკრაინა)',
      '2010 წ VI საერთაშორისო სტომატოლოგიური კონგრესი ბათუმი,სერტიფიკატი.',
      '2011 წ VII საერთაშორისო სტომატოლოგიური კონგრესი ,ბათუმი.სერტიფიკატი.',
      '2011 წ მასტერ კლასი,სემინარი "წარმატების გზები ენდოდონტიურ მკურნალობაში" მურატ სავური (DENTSPLY)',
      '2012-2013 წწ საქართველოს შრომის,ჯანმრთელობისა და სოციალური დაცვის სამინისტრო "მადლობის სიგელი" რეიტინგულ სტუდენტთა სტაჟირების პროგრამა 2012–2013 ში შეტანილი წვლილისთვის',
      '2014 წ სამეცნიერო–პრაქტიკული კონფერენცია.  ',
      '2014 წ X საერთაშორისო სტომატოლოგიური კონგრესი (ბათუმი) სერტიფიკატი.',
      ' 2014 წ GSA კონფერენცია"ახალი ხედვა სტომატოლოგიაში"  ქეთევან გოგილაშვილი.',
      ' 2014 წ GDPA მე 2–ე სტომატოლოგიური კონგრესი Georgia-Ukraine "კბილების მოდელირების ხელოვნება" ლარისა ლომიაშვილი (რუსეთი)მარინა მამალაძე (საქართველო)',
      '2018 წ GDPA სემინარი " ტკივილის მენეჯმენტი სტომატოლოგიაში "მარინა მამალაძე.',
      '2018 წ ლექცია მასტერ კლასი "შეცდომების დიაგნოსტირება და მკურნალობის თავისებურებები რთული არხების მექანიკური დამუშავების დროს" Leandro Pereira (Brazil) Victor Stopenko VDW (Russia) marina mamaladze (Georgia)',
      '2021 წ ინტენსიური სასწავლო კურსი "პაციენტის კურაცია და შემანარჩუნებელი თერაპია" ელენა კლიმენკო Dental Clinic Stedley Kyiv',
      '1994-2001წ ბათუმის მე 2 სტომატოლოგიური პოლიკლინიკა –ექიმი სტომატოლოგი',
      '2001–2023 სტომატოლოგიური კლინიკა  "Dentalux"თერაპევტი–სტომატოლოგი.',




    ]
  },
  {
    name: "ეთერ დიასამიძე",
    position: "სტომატოლოგი",
    tel: "123",
    img: eteri,
  },
  {
    name: "მაია დიასამიძე",
    position: "სტომატოლოგი-თერაპევტი",
    tel: "123",
    img: maiadiasamidze,
    education:[
      '1996-2001 მ. აბაშიძის სახელობის ბათუმის დამოუკიდებელი უნივერსიტეტი.(ექიმი სტომატოლოგი)',
      '2002წ საქართველოს სახელმწიფო სამედიცინო აკადემია. სახ. სასერთიფიკაციო გამოცდა (ზოგადი პროფილის ექიმ-სტომატოლოგი)',
      '2005წ საქართველოს სახელმწიფო სამედიცინო აკადემია, რეზიდენტურის მოკლე კურსი.(თერაპიული სტომატოლოგია.)',
      '2006წ საქართველოს სახელმწიფო სამედიცინო აკადემია, უწყვეტი პროფესიული განათლების ინსტიტუტი.(დაბალინტენსიური ლაზეროთერაპია.)',
      '2017-2018წ ბათუმის, შოთა რუსთაველის სახელმწიფო უნივერსიტეტი,დიპლომისშემდგომი სამედიცინო განათლების პროგრამა (ბავშთა და მოზარდთა თერაპიული სტომატოლოგია.)',
      '2009-დან დღემდე კლინიკა ‘’დენტა-ლუქსი’’-ში .'
      
    ]
  },
  {
    name: "ალექსი თორია",
    position: "სტომატოლოგი",
    tel: "123",
    img: aleqsi,
    education:[
      '2003-2008 ბათუმი მემედ აბაშიძის სახელობის დამოუკიდებელი უნივერსიტეტი - სპეციალობა ექიმ-სტომატოლოგი',
      '2009 წელს მიენიჭა უმაღლესი სამედიცინო განათლების სერთიფიკატი ნებადართული სპეციალობა–თერაპიული სტომატოლოგია.',
      '2010 წელს დაესწრო საქართველოს სტომატოლოგთა ასოციაციის VI საერთაშორისო კონგრესს.',
      '2019 წელს სამედიცინო განათლების სერთიფიკატი ნებადართული სპეციაობა–პირის ღრუს ქირურგიული სტომატოლოგია',
      '2008-დან დღემდე კლინიკა ‘’დენტა-ლუქსში’’ ექიმი სტომატოლოგის პოზიციაზე.',
      '2019-დან დღემდე კლინიკა ‘’დენტა-ლუქსი’’ ასევე სპეციალობა–პირის ღრუს ქირურგიული სტომატოლოგის პოზიციაზე.'
      
    ]
  },
  {
    name: "მაია ტალიკაძე",
    position: "სტომატოლოგი",
    tel: "123",
    img: maia,
  },
  {
    name: "მეგი მამულაძე",
    position: "სტომატოლოგი",
    tel: "123",
    img: megimamuladze,
  },
  {
    name: "მირანდა მალაყმაძე",
    position: "თერაპევტი-ქირურგ-იმპლანტოლოგი",
    tel: "123",
    img: np,
    education:[
      '2002 წელს ჩავირიცხე ბათუმის დამოუკიდებელ უნივერსიტეტში, ჯანდაცვის ფაკულტეტი – სპეციალობა "სტომატოლოგია"  რომელიც 2007 წელს დავამთავრე.',
      ' 20.09.2007-დან 20.11.2007-მდე გავიარე სპეციალიზაციის დახელოვნების ციკლი სპეციალობაში რენდგენო ლაბორანტი.შემიძლია ორთოპანტონოგრამის და დენტალური რენდგენო სურათის გადაღება',
      '06.09.2010 ჩავაბარე ხარკოვის სამედიცინო აკადემიაში 2010–2012წ.უკრაინა. დიპლომის შემდგომი განათლება ხარკოვის სამედიცინო აკადემია დიპლომის შემდეგი განათლება. XMAПО (სახელმწიფო სერტიფიკატი)',
      'გავიარე ორდინატურა (რეზიდენტურა) ექიმითერაპევტი სტომატოლოგი,რომელიც დავამთავრე 06.09.2012წ.',
      '2012წ  სერთიფიკატი უკრაინის ენდოდონტიის ასოციაციის მიერ გაცემული Tеоретический курс  “Современные технологии в эндодонтии”',
      '2012 წ.-მუშაობა Mtwo ინსტრუმენტებით - რთული ქეისები. ახალი საპასუხო სისტემის დემონსტრირება. ფესვის არხების სამგანზომილებიანი შევსება თერმოპლასტიკური გუტაპერჩათ მოწყობილობის გამოყენებით BiFil 2in1. ულტრაბგერის გამოყენება ენდოდონტიაში.',
      '2013 -სახელმწიფო სერთიფიკატი ,,თერაპიულ სტომატოლოგიაში" ',
      '2016-სახელმწიფო სერტიფიკატი ,,პირის ღრუს ქირურგიული სტომატოლოგია".',
      '2019 - გავიარე საბაზისო კურსი  "სტომატოლოგიური იმპლანტოლოგია" ',
      '2020-„Importance of The Quality for long-term Success" 2020 წელი-"ხარისხის მნიშვნელობა გრძელვადიანი წარმატებისთვის"'
    ],
  
  },
  {
    name: "ნოდარ მესხი",
    position: "სტომატოლოგი",
    tel: "123",
    img: nodari,
  },


  {
    name: "მარინე ტაკიძე",
    position: "სტომატოლოგი",
    tel: "123",
    img: marine,
    education:[
      '1997-2002 ბათუმი მემედ აბაშიძის სახელობის დამოუკიდებელი უნივერსიტეტი, სპეციალობა ექიმ-სტომატოლოგი.',
      '2003 წელს მიენიჭა უმაღლესი სამედიცინო განათლების დიპლომი ნებადართული სპეციალობა-ზოგადი პროფილის ექიმი სტომატოლოგი.',
      '2007 წელს მიენიჭა სახელმწიფო სერტიფიკატი,უმაღლესი სამედიცინო განათლების დიპლომი,ნებადართული სპეციალობა–თერაპიული სტომატოლოგია.',
      '2010 წელს დაესწრო საქართველოს სტომატოლოგთა ასოციაციის VI საერთაშორისო კონგრესს.',
      '2013 წელს დაესწრო, საქართველოს ახალგაზრდა სტომატოლოგთა საზოგადოების I ლაზერულ სტომატოლოგიურ კონგრესს.',
      '2014 წელს მსმენელის სახით მონაწილეობდა სამეცნიერო-პრაქტიკულ კონფერენციაში-სტომატოლოგია მაღალი სტანდარტებით',
      '2003-დან 2014-მდე ექიმი სტომატოლოგი კლინიკა ‘’პარაცელსში’’',
     '2010-დან დღემდე კლინიკა ‘’დენტა-ლუქსი’’ ექიმი სტომატოლოგის პოზიციაზე.'
    ]
  },


 


  {
    name: "ნინო ყუშიტაშვილი",
    position: "სტომატოლოგი",
    tel: "123",
    img: nino,
    education:[
        '2008-2013 ბათუმის შოთა რუსთაველის სახელობის სახელმწიფო უნივერსიტეტი-სტომატოლოგიური ფაკულტეტი',
        '2014-2016 თბილისი ნიუ-დენტი სარეზიდენტურო ბაზა,სპეციალობა-ორთოდონტია.',
        '2016წელს სახელმწიფო სერთიფიცირება მიმართულება-ორთოდონტია',
        '2016 წ. თბილისი სემინარი”თანამედროვე ორთოდონტია,ხარისხი და ეფექტურობა,აქტიური თვითლიგირებადი ბრეკეტებისა და სუპერელასტიური მავთულის გამოყენებით” გვიდო სამპერმანსი',
        '2017 წ.თბილისი სემინარი “ მოწინავე ორთოდონტია” ინდივიდუალური მიდგომა და სწრაფვა სრულყოფილებისკენ ალექსეი პეტროვი',
        '2019წ.სემინარი  “პასიური თვითლიგირება” DAMON SMILE ბადერ ბორგანი',
        '2019წ.ბაქო სემინარი “ბიომექანიკა+ესთეტიკაზე დაფუძნებული ორთოდონტიული მკურნალობის სტრატეგიები” რავინდრა ნანდა',
        '2020წ.თბილისი სემინარი,მასტერკლასი “პასიური თვითლიგირება” თამარ თოფურია',
        '2020წ.თბილისი ვებინარი “კლინიკური ცეფალომეტრია” თამარ თოფურია',
        '2021წ.თბილისი სემინარი,კონფერენცია Invisalign კონფერენცია სტეფანო მარინი,მარის გრზიბოვსკირი,მარიამ ფართაგაშვილი',
        '2021წ.თბილისი სემინარი “ფუნქციონალური დიაგნოსტიკა და ესთეთიკური დაგეგმარება,პაციენტის სრული რეაბილიტაცია” რომან ნესტორი',
        '2021წ.თბილისი სემინარი-კურსი “მარტივი და თავდაჯერებული გზა პასიურ თვითლიგირებასთან მუშაობის დასაწყებად,თანამედროვე კონცეფციები ორთოდონტიასა და ენდოდონტიაში” ნინო სიგუა',
        '2022წ ბათუმი კონფერენცია “პერიოდონტიტი და პერიიმპლანტიტი-ბიოლოგიურად ორიენტირებული მინიმალურად ინვაზიური მკურნალობა” ივან კოტოვიჩი',
        '2023წ.თბილისი სემინარი,მასტერკლასი “ოკლუზიური და სპლინტოთერაპია სტომატოლოგიაში” დიმიტრი დედკოვი',
        '2023წ.თბილისი “ინტენსიური ფანტომური კურსი სტომატოლოგიურ იმპლანტოლოგიაში” ბუბა ჩაჩხიანი',
    ],
 
  },


  {
    name: "პავლოვა დარია დიმიტრიევნა",
    position: "თერაპევტი პაროდონტოლოგი",
    tel: "123",
    img: daria,
    education:[
        '2012-2017 წწ კურსკის სახელმწიფო სამედიცინო უნივერსიტეტი ',
        'ინოვაციური ტექნოლოგიები სტომატოლოგიურ პრაქტიკაში" ანდრეი ზორიანი 18.07.2017',
        'ენდოდონტიური მკურნალობა დასრულებული კბილის ესთეტიკური და ფუნქციური აღდგენით" გილმიაროვი ედუარდი 11.11.2017',
        'პაროდონტის დაავადების მქონე პაციენტების კომპლექსური მკურნალობა  თამარა მოდინა 29.08.2017',
        'კბილების გათეთრება. კლინიკური და სახლის გათეთრების თანამედროვე მეთოდები 12.10.2017',
        'რუსეთის პაროდონტოლოგთა კლუბი. კლინიკური პაროდონტოლოგიის აქტუალური საკითხები 25.08.2017',
        'პირდაპირი კომპოზიციური ვინირები A-დან Z-მდე 20.05.2018წ',
        'ფესვის არხების ფორმირება, წმენდა და ობტურაცია 14.04.2018წ',
        'წინა კბილების კონსერვატიული პირდაპირი აღდგენის თანამედროვე ტექნოლოგიები 12.02.2018წ',
        'ქირურგიული კურსი პაროდონტოლოგიაში. თანამედროვე შეხედულება ეროხინი 11.03.2018',
        'ინტერდისციპლინური სტომატოლოგია: პაროდონტოლოგია და ორთოდონტია 25.08.2018',
        'კბილების საღეჭი ჯგუფის აღდგენა" რომან ალიევი  19.04.2019',
        'ორთოპედიული სტომატოლოგია. თანამედროვე ასპექტები 21.12.2020',
        
    ],
 
  },

];

export const works= [

  
  {
    img:work3,
    name:'ცირკონო-კერამიკული გვირგვინები',
    doctor:'ქეთევან ყუშიტაშვილი'


  },

  {
    img:work7,
    name:'ცირკონო-კერამიკული გვირგვინები',
    doctor:'ქეთევან ყუშიტაშვილი'


  },
  {
    img:work8,
    name:'ცირკონო-კერამიკული გვირგვინები',
    doctor:'ქეთევან ყუშიტაშვილი'
  },
  {
    img:cvin1,
    name:'კერამიკული ვინირები',
    doctor:'ქეთევან ყუშიტაშვილი'
  },
  {
    img:cvin2,
    name:'კერამიკული ვინირები',
    doctor:'ქეთევან ყუშიტაშვილი'
  },
  {
    img:br4,
    name:'ორთოდონტიული მკურნალობა ბრეკეტ სისტემით',
    doctor:'ნინო ყუშიტაშვილი'
  },
  {
    img:br5,
    name:'ორთოდონტიული მკურნალობა ბრეკეტ სისტემით',
    doctor:'ნინო ყუშიტაშვილი'
  },
  {
    img:br6,
    name:'ორთოდონტიული მკურნალობა ბრეკეტ სისტემით',
    doctor:'ნინო ყუშიტაშვილი'
  },
  {
    img:cvin3,
    name:'კერამიკული ვინირები',
    doctor:'ქეთევან ყუშიტაშვილი'
  },
  {
    img:cvin4,
    name:'კერამიკული ვინირები',
    doctor:'ქეთევან ყუშიტაშვილი'
  },
  {
    img:cirk1,
    name:'ცირკონის გვირგვინები',
    doctor:'ქეთევან ყუშიტაშვილი'
  },
  {
    img:br1,
    name:'ორთოდონტიული მკურნალობა ბრეკეტ სისტემით',
    doctor:'ნინო ყუშიტაშვილი'
  },
  {
    img:br2,
    name:'ორთოდონტიული მკურნალობა ბრეკეტ სისტემით',
    doctor:'ნინო ყუშიტაშვილი'
  },
  {
    img:br3,
    name:'ორთოდონტიული მკურნალობა ბრეკეტ სისტემით',
    doctor:'ნინო ყუშიტაშვილი'
  },



  {
    img:cirk2,
    name:'ცირკონის გვირგვინები',
    doctor:'ქეთევან ყუშიტაშვილი'
  },
  {
    img:cirk3,
    name:'ცირკონის გვირგვინები',
    doctor:'ქეთევან ყუშიტაშვილი'
  },
  {
    img:cirk4,
    name:'ცირკონის გვირგვინები',
    doctor:'ქეთევან ყუშიტაშვილი'
  },
]
