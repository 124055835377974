import { Navigation,Contacts, Footer } from "../Components"


export const ContactPage = () => {
  return (
    <>
    <Navigation />
    <Contacts />
    <Footer />

    
    </>
  )
}
