
import { Footer, Navigation, Works } from '../Components'

export  const WorkPage = () => {
  return (
    <>
    <Navigation />
    <Works />
    <Footer />
        
    </>
  )
}

 